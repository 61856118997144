import type {LinksFunction} from '@remix-run/server-runtime';
import {useLoaderData} from '@remix-run/react';

import type {Handle} from '@/types';
import {Theme} from '@/enums';
import customStyleSheet from '@/stylesheets/oberlo/oberlo.css?url';
import Blog from '@/components/pages/oberlo/Blog/Blog';
import OberloPageLayout from '@/components/pages/oberlo/PageLayout/PageLayout';

export const handle: Handle = {
  theme: Theme.Oberlo,
};

const links: LinksFunction = () => {
  return [{rel: 'preload stylesheet', as: 'css', href: customStyleSheet}];
};

export {oberloDefaultLoader as loader} from '@/pages/oberlo.com/($locale)/server/loaders/defaultLoader.server';

export default function OberloBlog() {
  const data = useLoaderData<any>() ?? {};

  return (
    <OberloPageLayout headerProps={{background: 'bg-white'}} links={links}>
      <Blog
        topics={data?.processedData?.topics}
        data={data?.processedData?.articles}
        pagination={data?.processedData?.pagination}
      />
    </OberloPageLayout>
  );
}
